import type { ProductCardProduct } from '@we-make-websites/ui-lib';
import { Shimmer, toBase64 } from '@we-make-websites/ui-lib';
import type { BaseHit } from 'instantsearch.js';
import { type StoreLocale } from '@/root/constants';
import { getCurrencyCode, getLocalizedFields } from '@/utils/localization';
import { getCleanProductHandle } from '../helpers';

/* eslint-disable */
type AlgoliaMeta = {
  [namespace: string]: {
    [key: string]: string | boolean | number;
  };
};

/**
 * Format an Algolia Hit and return the data in a standardised product format
 * for use by product cards and other product components.
 * - For note: Algolia only appears to return a single image under the image
 * key, the difference between this data and the standard Shopify product data
 * is the absence of an images array available on the images key.
 */
export type AlgoliaProductHit = BaseHit & {
  id: number;
  title: string;
  image: string;
  handle: string;
  price: number;
  product_image: string;
  compare_at_price: number;
  inventory_quantity: number;
  tags: string[];
  meta: AlgoliaMeta;
  named_tags: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
  product_type: string;
  objectID?: string;
  [key: string]: any;
};

export type AlgoliaProductHitParams = {
  hit: AlgoliaProductHit;
  collectionHandle: string | undefined;
  locale: string;
};

export const formatAlgoliaProductHit = (
  algoliaHitParams: AlgoliaProductHitParams
): ProductCardProduct => {
  const {
    hit: {
      id,
      price,
      product_image,
      compare_at_price,
      named_tags,
      tags,
      inventory_available,
      product_type,
      variant_title,
    },
    locale,
    collectionHandle,
  } = algoliaHitParams;
  // localized fields for CH locale
  const { handle, meta, title } = getLocalizedFields(algoliaHitParams);

  const currencyCode = getCurrencyCode(locale as StoreLocale);

  const cleanedHandle = getCleanProductHandle(handle ?? '');
  const systemName = product_type ?? '';
  const proOnly =
    meta?.wmw?.pro_only === true ||
    meta?.wmw?.pro_only === 'true' ||
    (typeof meta.pro_only === 'boolean' && meta.pro_only === true) ||
    (typeof meta.pro_only === 'string' && meta.pro_only === 'true');
  const ultaProductUrl = meta?.opi?.ulta_product_url?.toString();
  const customBuyNowLabel = meta?.opi?.custom_buy_now_label?.toString();
  const customBuyNowUrl = meta?.opi?.custom_buy_now_url?.toString();
  const asin = meta?.opi?.asin?.toString();
  const hoverImage = meta?.opi?.hover_image?.toString();
  const url = collectionHandle
    ? `/products/${collectionHandle}/${cleanedHandle}`
    : `/products/${cleanedHandle}`;

  const badges = named_tags.badge;
  const hasAwards = !!meta?.opi?.awards_icons?.toString();

  return {
    id: id.toString(),
    title,
    handle: cleanedHandle,
    image:
      product_image ??
      `data:image/svg+xml;base64,${toBase64(Shimmer(280, 333))}`,
    secondaryImage: hoverImage,
    price: {
      amount: price,
      currencyCode,
    },
    comparePrice: {
      amount: compare_at_price,
      currencyCode,
    },
    systemName,
    availableForSale: inventory_available,
    url,
    badges,
    tags,
    proOnly,
    ultaProductUrl,
    customBuyNowLabel,
    customBuyNowUrl,
    meta,
    asin,
    collectionHandle,
    variantTitle: variant_title,
    hasAwards,
  } as ProductCardProduct;
};
